import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import UserDashboard from './userDashboard';
import AdminDashboard from './adminDashboard';
import { useNavigate } from 'react-router-dom';
import { setApproverDocumentCount } from '../../utility';

const Dashboard = () => {
    const ls_userType = useSelector(content => content.commonReducer.user_type);
    const navigate = useNavigate();

    useEffect(() => {
        setApproverDocumentCount();
        console.log('navigate >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    }, [])

    // remove for later 
    useEffect(() => {
        if(ls_userType == 'user') navigate(`${process.env.PUBLIC_URL}/document/search-document`);
    }, [ls_userType])

    return (
        <>
            {/* {ls_userType == 'user' ? <UserDashboard /> : (ls_userType == 'admin' ? <AdminDashboard /> : '')} */}
            {ls_userType == 'admin' ? <AdminDashboard /> : ''}
        </>
    )
}

export default Dashboard