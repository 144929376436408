import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ExportOutlined } from '@ant-design/icons';
import { Button, Input, message, Row, Switch } from 'antd';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { getRequestApiByToken } from '../../../../api';
import { Table } from 'antd/lib';
import moment from 'moment/moment';
const { Search } = Input;

const startLoadTheMessage = (msg) => message.loading({ content: msg || 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

const UserPermissionReport = ({ }) => {
    const ls_userType = useSelector(content => content.commonReducer.user_type);
    const [la_reportList, setReportList] = useState([])
    const [la_columnsList, setColumnsLis] = useState([])
    const [filterText, setFilterText] = useState('');
    const [lb_loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

      const expandColumns = [
          {
              title: 'Sector Name',
              dataIndex: 'Name',
              key: 'Name',
          },
          {
              title: 'View',
              dataIndex: 'View',
              key: 'View',
          },
          {
              title: 'Download',
              dataIndex: 'Download',
              key: 'Download',
          },
          {
              title: 'Upload',
              dataIndex: 'Upload',
              key: 'Upload',
          },
          {
              title: 'Approve',
              dataIndex: 'Approve',
              key: 'Approve',
          },
          {
              title: 'Review Required',
              dataIndex: 'Review Required',
              key: 'Review Required',
          },
      ];
      const columns = [
        {
            title: 'Sl No',
            dataIndex: 'Sl No',
            key: 'Sl No',
        },
        {
          title: 'User Name',
          dataIndex: 'User Name',
          key: 'User Name',
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'Email',
        },
        {
          title: 'Mobile',
          dataIndex: 'Mobile',
          key: 'Mobile',
        },
        {
          title: 'Designation',
          dataIndex: 'Designation',
          key: 'Designation',
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          key: 'Status',
        },
      ];
      const expandedRowRender = (childValue) => {
        console.log('------------------------------------------------childValue',childValue)
        const la_expandDataSource = childValue?.Sectors || [];
        return (<Table columns={expandColumns} dataSource={la_expandDataSource} pagination={false} />)
      }
    const handleExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys([record['Sl No']]); // Expand only the clicked row
        } else {
            setExpandedRowKeys([]); // Collapse all rows
        }
    };

    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };

    const getReportList = async () => {
        console.log('---getReportList---');
        
        setLoading(true);

        const res = await getRequestApiByToken(`useraccess_report`);
        console.log('---userwise-report--', res);
        setReportList(res?.data || []);

        setLoading(false);
    }

    // const exportToExcel = () => {
    //     startLoadTheMessage('Exporting...');
    //     const ws = XLSX.utils.json_to_sheet(la_reportList.map(item => ({
    //         'Sl No': item['Sl No'], 'User Name': item['User Name'], 'Email': item['Email'], 'Mobile': item['Mobile'], 'Designation': item['Designation'], 'Status': item['Status'],
    //     })));
    
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, "User Permission Report");
    //     XLSX.writeFile(wb, "user_permission_report.xlsx");
    //     stopLoadingSuccessMsh('Exported!');
    // };


    const exportToExcel = () => {
        startLoadTheMessage('Exporting...');
    
        // Transform the data to match the desired structure
        const transformedData = [];
        la_reportList.forEach((user, index) => {
            const sectors = user?.Sectors || []; // Extract sectors if available
            if (sectors.length > 0) {
                sectors.forEach((sector, inx) => {
                    transformedData.push({
                        'Sl No': (inx == 0) ? index + 1 : '', // Generate serial number
                        'User Name': (inx == 0) ? user['User Name'] : '',
                        'Email': (inx == 0) ? user['Email'] : '',
                        'Mobile': (inx == 0) ? user['Mobile'] : '',
                        'Designation': (inx == 0) ? user['Designation'] : '',
                        'Status': (inx == 0) ? user['Status'] : '',
                        'Sector Name': sector['Name'] || '',
                        'View': sector['View'] || '',
                        'Download': sector['Download'] || '',
                        'Upload': sector['Upload'] || '',
                        'Approve': sector['Approve'] || '',
                        'Review Required': sector['Review Required'] || '',
                    });
                });
            } else {
                // If no sectors, add user data with empty sector fields
                transformedData.push({
                    'Sl No': index + 1,
                    'User Name': user['User Name'],
                    'Email': user['Email'],
                    'Mobile': user['Mobile'],
                    'Designation': user['Designation'],
                    'Status': user['Status'],
                    'Sector Name': '',
                    'View': '',
                    'Download': '',
                    'Upload': '',
                    'Approve': '',
                    'Review Required': '',
                });
            }
        });
    
        // Generate Excel sheet
        const ws = XLSX.utils.json_to_sheet(transformedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "User Permission Report");
    
        // Save the file
        XLSX.writeFile(wb, "user_permission_report"+moment().format("DD-MM-YYYY--HH:mm:ss:SSS")+".xlsx");
    
        stopLoadingSuccessMsh('Exported!');
    };
    

    useEffect(() => {
        getReportList()
    }, [])

    useEffect(() => {
        const columnList = [...columns];
        setColumnsLis(columnList);
    }, [ls_userType])


    return (
        <>
            <div>
                <div className="col-xl-12 xl-100">
                    <div className="card parent-tblsec">
                        <div className="card-header card-header-border">
                            <div className="row" style={{ alignItems: 'center' }}>
                                <div className="col-sm-7">

                                    <h5>{"User Permission Report"}</h5>

                                </div>
                                <div className="col-sm-5">
                                    <div className="pull-right right-header">
                                        <div className="onhover-dropdown">
                                            <div style={{ display: "flex", alignItems: "stretch", justifyContent: "space-between" }}>
                                                <div style={{ paddingRight: 8 }}>
                                                    <Button disabled={!la_reportList?.length} onClick={exportToExcel} type="dashed" icon={<ExportOutlined />}>Export</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <Table
                                    rowKey="Sl No"
                                    columns={la_columnsList}
                                    expandable={{
                                        expandedRowRender,
                                        expandedRowKeys,
                                        onExpand: handleExpand,
                                    }}
                                    dataSource={la_reportList}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserPermissionReport