import React, { useState, useEffect } from "react";
import './style.css'
import loginbgImg from "../../../assets/images/auth/login-bg.jpg";
import logo from '../../../assets/images/logo/login-logo.png';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Col, Row, message } from "antd";
import { postRequestApi } from "../../../api";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { saveFcmToken } from "../../../api/commonApi";
// import MicrosoftLoginPage from "../MicrosoftLogin";

import andriodImg from '../../../assets/images/logo/andriod-dwn1.png';
import iosImg from '../../../assets/images/logo/ios-dwn1.png';

const UserLogin = ({header=''}) => {

  // const [userEmail, setUserEmail] = useState("user@gmail.com");
  // const [userPassword, setUserPassword] = useState("user123");
  
  // const url = '';
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { instance } = useMsal();
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [lb_userLoginType, setUserLoginType] = useState(false);
  const [lb_loginLoading, setLoginLoading] = useState(false);

  const onChangeLoginType = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setUserLoginType(!lb_userLoginType)
  };
  const setUserDEtails = (userType, userId, authToken, userDetails, sector) => {
    dispatch({ type: 'SET_USER_TOKEN', payload: authToken });
    dispatch({ type: 'SET_USER_ID', payload: userId });
    dispatch({ type: 'SET_USER_TYPE', payload: userType });
    dispatch({ type: 'SET_USER_DETAILS', payload: {...userDetails} });
    dispatch({ type: 'SET_LOGIN_TYPE', payload: 'normal' });
    dispatch({ type: 'SET_HAS_SECTOR', payload: sector });
  }
  const loginAuth = async () => {
    setLoginLoading(true);
    console.log('loginAuth');
    if (!email || !password) {
        console.error("Email or Password can not be empty!");
        setError("Email or Password can not be empty!");
        return;
    }

    const lo_formData = {'email':email , 'password':password}
    const lo_response = await postRequestApi("login", lo_formData)
    console.log('login--',lo_response);
    if (lo_response?.success) {
      const usrTyp = (lo_response?.user?.user_type_id == 1) ? 'admin' : 'user';
      setUserDEtails(usrTyp, lo_response?.user?.id, lo_response?.token?.token, lo_response?.user, lo_response?.sector);
      setError(null);
      message.success("Logged in successful!");
      console.log('User logged in successfully',usrTyp);
      saveFcmToken(lo_response?.token?.token);
      
      if (usrTyp == 'user') {
        navigate(`${process.env.PUBLIC_URL}/document/search-document`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      }
    }else{
      setError(lo_response?.message)
    }

    setLoginLoading(false);
  }
  

  const redirectToMSALoginPage = () => {
    // if (instance) {
    //   instance.logout({
    //     postLogoutRedirectUri: `${window.location.origin}${process.env.PUBLIC_URL}/msa-login`
    //   });
    // } else {
    //   navigate(`${process.env.PUBLIC_URL}/msa-login`);
    // }
    navigate(`${process.env.PUBLIC_URL}/msa-login`);
  }

  

  return (
    <div >
      <div className="page-wrapper" >
        <div className="container-fluid p-0" >
          {/* <!-- login page start--> */}
          <div className="authentication-main" style={{ background: `url(${loginbgImg})`, backgroundSize:"cover"}}>
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">

                {/* <div className="text-center" style={{ position: 'absolute', top: '50%', left: '10%', transform: 'translateY(-50%)' }}>
                  <div style={{display:"flex"}}>
                    <div span={12}>Search Across 20 Filters Quickly</div>
                    <div span={12}>Upload IPE docs in a Flash</div>
                    <div span={12}>Project Datasheet</div>
                    <div span={12}>Workflow Enhancement</div>
                    <div span={12}>Quick Access</div>
                    <div span={12}>Download Files Instantly</div>
                    <div span={12}>Administration</div>
                    <div span={12}>Share Document</div>
                  </div>
                </div> */}


                  <div className="text-center" style={{ position: 'absolute', top: '85%', left: '18%', transform: 'translateY(-50%)' }}>
                    <div className="app-links">
                      {/* Google Play Badge */}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ipesharelive.ipeglobal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={andriodImg}
                          alt="Get it on Google Play"
                          className="app-badge android-app-img"
                        />
                      </a>

                      {/* App Store Badge */}
                      <a
                        href="https://apps.apple.com/us/app/ipeshare/id6737738487"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={iosImg}
                          alt="Download on the App Store"
                          className="app-badge android-app-img"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-md-5 offset-md-6">
                    <div className="text-center">
                      <img src={logo} alt="" style={{width:"152px"}}/>
                    </div>
                    <div className="card mt-2">
                      <div className="card-body">
                        <div className="text-center">
                          <h5> <strong>Welcome to IPE Global's Knowledge Sharing Portal</strong></h5>
                          {/* <h6>{"Enter your Id and Password"} </h6> */}
                        </div>

                        {lb_userLoginType ?
                        <div>
                          <form className="theme-form">
                            <div className="text-center"><h6>{"Enter your Id and Password"} </h6></div>
                            <div className="form-group">
                              <label className="col-form-label pt-0">{'User Id'}</label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter Your Email address"
                              />
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">{'Password'}</label>
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter Your Password"
                              />
                            </div>
                            {error && <div className="text-danger">{error}</div>}
                            <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" />
                              <label htmlFor="checkbox1">{'RememberMe'}</label>
                            </div>
                            <div className="form-group form-row mt-3 mb-0 d-grid">
                              <Button loading={lb_loginLoading} className="btn btn-primary" htmlType="button" onClick={() => loginAuth()}>
                                {'Login'}
                              </Button>
                            </div>
                          </form>
                        </div>
                        : <></>}

                        <div className="theme-form">
                          {lb_userLoginType ? <div className="login-divider mb-2"></div> : ''}
                          <div className="social mt-3">
                            <div className="form-group btn-showcase d-flex mb-0" style={{alignItems:"center", flexDirection:"column"}}>

                              {/* <MicrosoftLoginPage/> */}

                              <button onClick={redirectToMSALoginPage} style={{ background: "#2f2f2ff0", height: 50, display: "flex", alignItems: "center" }} type="button" className="btn social-btn btn-fb mb-2 text-center"><img style={{ width: 35, marginRight: 8 }} src={'https://purepng.com/public/uploads/large/purepng.com-microsoft-logo-iconlogobrand-logoiconslogos-251519939091wmudn.png'} className="align-self-center pull-left  blur-up lazyloaded" />{'Sign in with Microsoft'}</button>
                              <Checkbox value={lb_userLoginType} onChange={onChangeLoginType}>Login as other user</Checkbox>
                            </div>
                          </div>
                        </div>

                        


                      </div>
                    </div>
                  </div>

                  


                </div>
              </div>
            </div>
          </div>
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default UserLogin;