import React, { useEffect, useState } from 'react'
import './style.css'
import DataTable from 'react-data-table-component'
import { Input, message, Modal, Switch, Tag, Tooltip } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import moment from 'moment';
import { FileDoneOutlined, FormOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { ExclamationCircleFilled, StopOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';
import Breadcrumb from '../../../components/common/breadcrumb';
import { DateFormatForView } from '../../../constant/basicConstant';
import LimitRequestModal from '../../document/LimitRequestModal';
import { setNotificationSeen } from '../../../api/commonApi';
import { setNotificationCountData, setNotificationsListData } from '../../../utility';
import Radio from 'antd/lib/radio/radio';


const { Search } = Input;

const NotificationList = () => {

    const navigate = useNavigate();
    const [la_notificationsList, setNotificationsList] = useState([])
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [lb_buttonLoading, setButtonLoading] = useState(false)

    const [ls_docAction, setForDocAction] = useState('')
    const [ls_listType, setListType] = useState('unread');

    const updateLatestNotificationsResponse = async () => {
        await setNotificationsListData();
        await setNotificationCountData();
      }

    const handleView = async (rowValue) => {
        let lo_formData = { document_id: rowValue?.document_id, type: 'view' }
        const res = await postRequestApiByToken('documentaction', lo_formData);
        console.log('ressss>>> handleView >>>', res);
        if (res?.status === "Success") {
            if (rowValue?.is_seen == 0) {
                await setNotificationSeen(rowValue?.id)
                getNotificationsList()
                updateLatestNotificationsResponse()
            }
            navigate('/document/document-details', { state: { documentId: rowValue?.document_id, viewType: 'communication' } });
        } else {
            message.error(res?.message || 'Access denied!');
            setForDocAction('request-for-limit')
        }
    }

    const handleRowClick = (row) => {
        handleView(row);
    };
    const conditionalRowStyles = [
        {
            when: row => true,
            style: {
                cursor: 'pointer',
            },
        },
    ];

    const columns = [
        {
            name: "#",
            selector: (row) => <div onClick={() => handleRowClick(row)} className='break-tablerow'>{row?.is_seen == 1 ? <span style={{color:'green'}}><EyeOutlined /></span> : <span style={{color:'gray'}}><EyeInvisibleOutlined /></span>} </div>,
            sortable: false,
            // center: true,
            width: '45px',
        },
        {
            selector: (row) => <div onClick={() => handleRowClick(row)} className='break-tablerow'>{row?.message} </div>,
            name: "Comment",
            sortable: true,
            // center: true,
            // width: '120px',
        },
        {
            selector: (row) => <div onClick={() => handleRowClick(row)} className='break-tablerow'>{row?.sent_from?.name} </div>,
            name: "Messaged by",
            sortable: true,
            center: true,
            width: '170px',
        },
        // {
        //     selector: (row) => <div className='break-tablerow'>{row?.sent_to?.name} </div>,
        //     name: "Commented to",
        //     sortable: true,
        //     center: true,
        //     width: '170px',
        // },
        {
            selector: (row) => <>{row?.updated_at ? moment(row?.updated_at).format(DateFormatForView) : ''}</>,
            name: "Messaged On",
            sortable: true,
            center: true,
            width: '160px'
        },
        // {
        //     selector: (row) =>
        //         <div style={{ display: "flex", alignItems: "center"}}>
        //             <div>
        //                 <Link onClick={()=>handleView(row)} ><i className="fa fa-eye text-secondary" style={{ fontSize: 18, marginInlineEnd: 12 }}></i></Link>
        //             </div>
        //         </div>,
        //     name: "View",
        //     center: true,
        //     width: '70px',
        // },
    ];

    const getNotificationsList = async () => {
        setLoading(true)
        let ls_apiUrl = `getNotification?search=${filterText}&page=${currentPage}&orderBy=desc`;
        if (ls_listType != 'all') {
            let seenStatus = (ls_listType == 'unread') ? 0 : 1;
            ls_apiUrl = `getNotification?search=${filterText}&page=${currentPage}&orderBy=desc&is_seen=${seenStatus}`;
        }
        const res = await getRequestApiByToken(ls_apiUrl)
        console.log('res', res);
        // dispatch({ type: 'SET_APPROVE_DOCUMENTS_COUNT', payload: res?.Documents?.total });
        setNotificationsList(res?.data?.data)
        setTotalCount(res?.data?.total);
        setLoading(false)
    }

    useEffect(() => {
        getNotificationsList()
    }, [currentPage, filterText, ls_listType])

    // Function to handle search input change
    const handleSearch = debounce((value) => {
        setFilterText(value);
    }, 300);

    return (
        <>
            <Breadcrumb parent="Notification List" title="" />
            <div className="col-xl-12 xl-100">
                <div className="card parent-tblsec">
                    <div className="card-header card-header-border">
                        <div className="row" style={{ alignItems: 'center' }}>
                            <div className="col-sm-7">
                                <h5>{"Notification List"}</h5>
                            </div>
                            <div className="col-sm-5">
                                <div className="pull-right right-header">
                                    <div className="onhover-dropdown">
                                        {/* <Input value={filterText} addonAfter={<SearchOutlined />} onChange={handleSearch} placeholder="Serach here..." /> */}
                                        <Search allowClear onChange={(e) => handleSearch(e.target.value)} placeholder="Search here..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div style={{margin:'-18px 0px 12px 0px'}}>
                            <Radio.Group autoFocus={true} defaultValue="unread" buttonStyle="solid" onChange={(e) => setListType(e?.target?.value)}>
                                <Radio.Button value="unread">Unread</Radio.Button>
                                <Radio.Button value="read">Read</Radio.Button>
                                <Radio.Button value="all">All</Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="table-responsive">
                            <DataTable
                                columns={columns}
                                data={la_notificationsList}
                                striped={true}
                                center={true}
                                persistTableHead
                                pagination
                                paginationServer
                                paginationTotalRows={totalCount} // Assuming 10 rows per page
                                paginationPerPage={10} // Number of rows per page
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                                paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                                onChangePage={setCurrentPage}
                                progressPending={loading}
                                progressComponent={<div><LoadingOutlined /></div>}
                                onRowClicked={handleRowClick}
                                conditionalRowStyles={conditionalRowStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <LimitRequestModal {...{setForDocAction, ls_docAction}} />
        </>
    )
}

export default NotificationList