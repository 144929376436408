import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Select, Space } from 'antd'
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import './style.css'
import { message, Switch } from 'antd/lib';
import { postRequestApiByToken } from '../../../api';

const DeviceId = ({ formUser, getuserinfo }) => {
    const [ls_switchLoading, setSwitchLoading] = useState(null);
    const deviceIdDetails =  Form.useWatch('device_id', formUser);

    const onChangeStatus = async (checked, key, row) => {
        setSwitchLoading((key+1) || null);
        let lo_formData = { status: (checked == true ? 1 : 0), id: row.tblid }
        const res = await postRequestApiByToken('device_status_update', lo_formData)
        setSwitchLoading(null);
        if (res?.status == "Success") {
            getuserinfo();
            message.success(`${row?.status == 1 ? 'Incativated' : 'Activated'} Successfully!`);
        }
    };

    useEffect(() => {
      console.log('---deviceIdDetails--',deviceIdDetails);
      
    }, [deviceIdDetails])
    
    return (
        <div className='ipformitem-space'>
            
            <Form.Item
            noStyle
                // labelAlign="right"
                // label="Capacity"
                // name={['ip_access']}
            >
                <Form.List name={['device_id']} initialValue={[{type:'1', id:''},{type:'2', id:''}]}>
                    {(fields, { add, remove }) => (
                        <>

                            {fields.map((field, index) => (
                                <div
                                    key={field.key}
                                    className='deviceformitem-list'
                                    // align="baseline"
                                >
                                    <Form.Item
                                        style={{ marginBottom: 10 , width:'25%', marginRight:8}}
                                        name={[field.name, 'type']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select" style={{ width: '100%' }} >
                                            <Select.Option value={'1'} key={1}>Desktop</Select.Option>
                                            <Select.Option value={'2'} key={2}>Mobile</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name={[field.name, 'id']}
                                        style={{ marginBottom: 10, width:'70%', marginRight:8 }}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'required!',
                                        //     },
                                        // ]}

                                    >
                                        <Input ></Input>
                                    </Form.Item>
                                    
                                    {
                                        <>
                                            {field.name > 0 ? <MinusCircleOutlined style={{color:"red"}} onClick={() => remove(index)} /> : <PlusCircleOutlined style={{color:"blue"}} onClick={() => add()} />}
                                            &nbsp;
                                            {(deviceIdDetails && deviceIdDetails[field?.key] && deviceIdDetails[field?.key]?.id) ?
                                                <Switch loading={ls_switchLoading == field?.key+1} defaultChecked={(deviceIdDetails && deviceIdDetails[field?.key] && deviceIdDetails[field?.key]?.status)} size='small' 
                                            onChange={(s) => onChangeStatus(s, field?.key, deviceIdDetails[field?.key])} 
                                            />
                                            :
                                            <div style={{width: 30}}></div>
                                            }
                                            
                                        </>
                                    }


                                </div>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </div>
    )
}

export default DeviceId